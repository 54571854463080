import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
    FaMapMarkerAlt,
    FaExpandArrowsAlt,
    FaMoneyBillWave,
    FaHome,
    FaCompass,
    FaHouseUser,
    FaHammer,
    FaUser,
    FaEnvelope,
    FaCommentAlt,
    FaSearch,
    FaPhone,
    FaSwimmingPool,
    FaTree,
    FaWarehouse,
    FaWrench,
    FaInfoCircle
} from 'react-icons/fa';
import { MdBalcony } from "react-icons/md";
import parse from 'html-react-parser';
import Card from './Card';
import emailjs from 'emailjs-com';
import { FaShop } from "react-icons/fa6";
import { PiWallFill } from "react-icons/pi";

const ListingDetails = () => {
    const { id } = useParams();
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [similarListings, setSimilarListings] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [mapCoordinates, setMapCoordinates] = useState(null);

    // Fix for Leaflet marker icon
    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41]
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    useEffect(() => {
        const fetchListings = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get('https://www.wivfy.space/php/src/api/ogloszenia.php');
                console.log('Response from API:', response.data);

                if (response.data && response.data.length > 0) {
                    setListings(response.data);
                    const currentListing = response.data.find(listing => listing.id_ogloszenia === parseInt(id));
                    setSimilarListings(getRandomListings(response.data, currentListing));

                    // Fetch coordinates for the current listing
                    if (currentListing && currentListing.ulica && currentListing.miejscowosc_dzielnica) {
                        await fetchCoordinates(currentListing);
                    }
                } else {
                    setError('Brak ogłoszeń do wyświetlenia.');
                }
            } catch (err) {
                setError('Wystąpił błąd podczas pobierania ogłoszeń!');
            } finally {
                setLoading(false);
            }
        };

        fetchListings();
    }, [id]);

    // New function to fetch coordinates using Nominatim OpenStreetMap API
    const fetchCoordinates = async (listing) => {
        try {
            const address = `${listing.ulica}, ${listing.miejscowosc_dzielnica}, Poland`;
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address,
                    limit: 1
                }
            });

            if (response.data && response.data.length > 0) {
                const { lat, lon } = response.data[0];
                setMapCoordinates({ lat: parseFloat(lat), lng: parseFloat(lon) });
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
        }
    };

    const getRandomListings = (listings, currentListing) => {
        const filteredListings = listings.filter(listing => listing.id_ogloszenia !== currentListing.id_ogloszenia);
        return filteredListings.sort(() => 0.5 - Math.random()).slice(0, 4);
    };

    const findListingById = (id) => {
        return listings.find(listing => listing.id_ogloszenia === parseInt(id));
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        if (!formData.name || !formData.email || !formData.message) {
            return 'Wszystkie pola są wymagane.';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            return 'Wprowadź poprawny adres e-mail.';
        }
        return '';
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errorMessage = validateForm();
        if (errorMessage) {
            setFormError(errorMessage);
            return;
        }

        setIsSubmitting(true);
        setFormError('');
        setFormSuccess('');

        // Przygotowanie danych do wysłania z formularza
        const templateParams = {
            from_name: formData.name,
            from_email: formData.email,
            message: formData.message,
        };

        emailjs.send(
            'service_mvc1i8j', // ID usługi z EmailJS
            'template_sv4cs94', // ID szablonu z EmailJS
            templateParams,
            'WkbubIL6__yDJts-Z' // Public Key z EmailJS
        )
            .then((result) => {
                setFormSuccess('Wiadomość została wysłana pomyślnie!');
                setIsSubmitting(false);
                setFormData({ name: '', email: '', message: '' });
            })
            .catch((error) => {
                console.error('Wystąpił błąd podczas wysyłania wiadomości:', error);
                setFormError('Wystąpił błąd podczas wysyłania wiadomości.');
                setIsSubmitting(false);
            });
    };

    if (loading) {
        return <div>Ładowanie...</div>;
    }

    if (error) {
        return <div>Błąd: {error}</div>;
    }

    const listing = findListingById(id);

    if (!listing) {
        return <div>Ogłoszenie o podanym id nie zostało znalezione.</div>;
    }

    const showFullDescriptionHandler = () => {
        setShowFullDescription(true);
    };

    // Sanitize the description before displaying
    const descriptionToShow = showFullDescription
        ? listing.opis.replace(/(\r\n|\n|\r)/gm, " ")
        : `${listing.opis.substring(0, 550).replace(/(\r\n|\n|\r)/gm, " ")}...`;

    const openModal = (photo) => {
        setSelectedImage(photo);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage('');
    };

    const formatText = (text) => {
        // Zamiana pierwszej litery na wielką
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const replacePolishCharacters = (text) => {
        return text
            .replace(/wtorny/g, 'Wtórny')
            .replace(/sprzedaz/g, 'Sprzedaż');
    };

    const formatNumber = (number) => {
        // Sprawdzenie i konwersja wartości na liczbę
        const num = parseFloat(number);
        if (isNaN(num)) return number; // Zwróć oryginalny tekst, jeśli nie jest liczbą
        return num.toFixed(2).replace('.', ',');
    };

    const formatCurrency = (amount) => {
        const num = parseFloat(amount);
        if (isNaN(num)) return amount; // Zwróć oryginalny tekst, jeśli nie jest liczbą

        // Formatowanie liczby z użyciem kropek jako separatorów tysięcy
        let formattedNumber = num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `${formattedNumber} zł`;
    };

    const formatRoomsText = (numberOfRooms) => {
        if (numberOfRooms === 1) {
            return '1 pokój';
        } else if (numberOfRooms >= 2 && numberOfRooms <= 4) {
            return `${numberOfRooms} pokoje`;
        } else {
            return `${numberOfRooms} pokój`;
        }
    };

    return (
        <div className="container mx-auto p-6">
            <Link to="/" className="text-[#a07c5c] hover:text-[#795b40]">&larr; Powrót</Link>
            <h1 className="text-3xl font-bold my-6">{listing.tytul}</h1>
            <hr className="border-gray-400 py-2"/>
            <div className="flex flex-col lg:flex-row gap-6">
                <div className="lg:w-2/3 bg-white shadow-md rounded-lg p-6">
                    {listing.zdjecia && listing.zdjecia.length > 0 ? (
                        <>
                            <Carousel
                                showThumbs={true}
                                autoPlay
                                infiniteLoop
                                renderThumbs={() =>
                                    listing.zdjecia.map((photo, index) => (
                                        <img
                                            key={index}
                                            src={photo}
                                            alt={`Thumbnail ${index + 1}`}
                                            className="object-cover  cursor-pointer"
                                            onClick={() => openModal(photo)}
                                        />
                                    ))
                                }
                            >
                                {listing.zdjecia.map((photo, index) => (
                                    <div key={index}>
                                        <img src={photo} alt={`Slide ${index + 1}`}/>
                                    </div>
                                ))}
                            </Carousel>
                            {isModalOpen && (
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                                    <div className="relative">
                                        <button onClick={closeModal}
                                                className="absolute top-2 right-2 text-white text-3xl">&times;</button>
                                        <img src={selectedImage} alt="Selected"
                                             className="max-w-screen-lg max-h-screen"/>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <p>Brak zdjęć do wyświetlenia.</p>
                    )}

                    <div className="mt-6">
                        <h2 className="text-2xl font-bold mb-4">Informacje</h2>
                        <hr className="border-gray-400 py-2"/>
                        <div className="grid grid-cols-2 gap-4">
                            {listing.typ_nieruchomosci && (
                                <div className="flex items-center space-x-2">
                                    <FaHome className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatText(listing.typ_nieruchomosci)}</span>
                                </div>
                            )}
                            {listing.transakcja && (
                                <div className="flex items-center space-x-2">
                                    <FaCompass className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatText(replacePolishCharacters(listing.transakcja))}</span>
                                </div>
                            )}
                            {listing.miejscowosc_dzielnica && (
                                <div className="flex items-center space-x-2">
                                    <FaMapMarkerAlt className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatText(listing.miejscowosc_dzielnica)}</span>
                                </div>
                            )}
                            {listing.ulica && (
                                <div className="flex items-center space-x-2">
                                    <FaMapMarkerAlt className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>ul. {formatText(listing.ulica)}</span>
                                </div>
                            )}
                            {listing.cena && (
                                <div className="flex items-center space-x-2">
                                    <FaMoneyBillWave className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatCurrency(listing.cena)}</span>
                                </div>
                            )}
                            {listing.cena_m2 && (
                                <div className="flex items-center space-x-2">
                                    <FaMoneyBillWave className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatCurrency(listing.cena_m2)}/m²</span>
                                </div>
                            )}
                            {listing.powierzchnia && (
                                <div className="flex items-center space-x-2">
                                    <FaExpandArrowsAlt className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatNumber(listing.powierzchnia)} m²</span>
                                </div>
                            )}
                            {listing.liczba_pokoi && (
                                <div className="flex items-center space-x-2">
                                    <FaHouseUser className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatRoomsText(listing.liczba_pokoi)}</span>
                                </div>
                            )}
                            {listing.rynek && (
                                <div className="flex items-center space-x-2">
                                    <FaShop className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{formatText(replacePolishCharacters(listing.rynek))}</span>
                                </div>
                            )}
                            {listing.rok_budowy && (
                                <div className="flex items-center space-x-2">
                                    <FaHammer className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{listing.rok_budowy}</span>
                                </div>
                            )}
                        </div>
                        <h2 className="text-2xl font-bold mb-4 mt-6">Dodatkowe</h2>
                        <hr className="border-gray-400 py-2"/>
                        <div className="grid grid-cols-2 gap-4">
                            {listing.garaz === 1 && (
                                <div className="flex items-center space-x-2">
                                    <FaWarehouse className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>Garaż</span>
                                </div>
                            )}
                            {listing.basen === 1 && (
                                <div className="flex items-center space-x-2">
                                    <FaSwimmingPool className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>Basen</span>
                                </div>
                            )}
                            {listing.ogrod === 1 && (
                                <div className="flex items-center space-x-2">
                                    <FaTree className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>Ogród TAK</span>
                                </div>
                            )}
                            {listing.balkon === 1 && (
                                <div className="flex items-center space-x-2">
                                    <MdBalcony className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>Balkon</span>
                                </div>
                            )}
                            {listing.taras === 1 && (
                                <div className="flex items-center space-x-2">
                                    <PiWallFill className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>Taras</span>
                                </div>
                            )}
                            {listing.miejsce_parkingowe && (
                                <div className="flex items-center space-x-2">
                                    <FaInfoCircle className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>Miejsce Parkingowe {listing.miejsce_parkingowe}</span>
                                </div>
                            )}
                            {listing.stan_wykonczenia && (
                                <div className="flex items-center space-x-2">
                                    <FaWrench className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{listing.stan_wykonczenia}</span>
                                </div>
                            )}
                            {listing.dodatkowy_parametr1 && (
                                <div className="flex items-center space-x-2">
                                    <FaInfoCircle className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{listing.dodatkowy_parametr1}</span>
                                </div>
                            )}
                            {listing.dodatkowy_parametr2 && (
                                <div className="flex items-center space-x-2">
                                    <FaInfoCircle className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{listing.dodatkowy_parametr2}</span>
                                </div>
                            )}
                            {listing.dodatkowy_parametr3 && (
                                <div className="flex items-center space-x-2">
                                    <FaInfoCircle className="h-5 w-5 text-[#a07c5c]"/>
                                    <span>{listing.dodatkowy_parametr3}</span>
                                </div>
                            )}
                        </div>
                        {(listing.typ_dzialki || listing.numer_dzialki ||
                            listing.wymiary_dzialki || listing.ksztalt_dzialki || listing.ogrodzenie || listing.droga_dojazdowa) && (
                            <div className="mt-10">
                                <h2 className="text-2xl font-bold mb-4">Informacje o działce</h2>
                                <hr className="border-gray-400 py-2"/>
                                <div className="grid grid-cols-2 gap-4">
                                    {listing.typ_dzialki && (
                                        <div className="flex items-center space-x-2">
                                            <FaTree className="h-5 w-5 text-[#a07c5c]"/>
                                            <span>Typ działki: {listing.typ_dzialki}</span>
                                        </div>
                                    )}
                                    {listing.numer_dzialki && (
                                        <div className="flex items-center space-x-2">
                                            <FaInfoCircle className="h-5 w-5 text-[#a07c5c]"/>
                                            <span>Nr działki: {listing.numer_dzialki}</span>
                                        </div>
                                    )}
                                    {listing.wymiary_dzialki && (
                                        <div className="flex items-center space-x-2">
                                            <FaExpandArrowsAlt className="h-5 w-5 text-[#a07c5c]"/>
                                            <span>Wymiary: {listing.wymiary_dzialki}</span>
                                        </div>
                                    )}
                                    {listing.ksztalt_dzialki && (
                                        <div className="flex items-center space-x-2">
                                            <FaInfoCircle className="h-5 w-5 text-[#a07c5c]"/>
                                            <span>Kształt: {listing.ksztalt_dzialki}</span>
                                        </div>
                                    )}
                                    {listing.ogrodzenie && (
                                        <div className="flex items-center space-x-2">
                                            <PiWallFill className="h-5 w-5 text-[#a07c5c]"/>
                                            <span>Ogrodzenie: {listing.ogrodzenie}</span>
                                        </div>
                                    )}
                                    {listing.droga_dojazdowa && (
                                        <div className="flex items-center space-x-2">
                                            <FaWrench className="h-5 w-5 text-[#a07c5c]"/>
                                            <span>Droga dojazdowa: {listing.droga_dojazdowa}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="mt-6">
                            <h2 className="text-2xl font-bold mb-4">Opis</h2>
                            <hr className="border-gray-400 py-2"/>
                            <div>{parse(descriptionToShow)}</div>
                            {!showFullDescription && listing.opis.length > 550 && (
                                <button
                                    onClick={showFullDescriptionHandler}
                                    className="text-[#a07c5c] hover:text-[#795b40] mt-2 focus:outline-none"
                                >
                                    Pokaż więcej
                                </button>
                            )}
                        </div>
                    </div>

                </div>

                <div className="lg:w-1/3 space-y-6">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4">{listing.agent_imie}</h2>
                        <div className="space-y-2">

                            <p className="flex items-center space-x-2">
                                <FaPhone className="h-5 w-5 text-[#a07c5c]"/>
                                <span>{listing.telefon}</span>
                            </p>
                            <p className="flex items-center space-x-2">
                                <FaEnvelope className="h-5 w-5 text-[#a07c5c]"/>
                                <span>{listing.email}</span>
                            </p>
                        </div>
                    </div>

                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-bold">Kontakt</h2>
                        <h4 className="text-sm mb-4">Zainteresowała Cię ta oferta? Napisz do nas!</h4>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <div className="flex items-center space-x-2">
                                    <FaUser className="text-[#a07c5c] w-5 h-5"/>
                                    <label htmlFor="name" className="text-sm font-medium text-gray-700">Imię i
                                        nazwisko</label>
                                </div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#795b40] focus:ring focus:ring-[#795b40] focus:ring-opacity-50"
                                />
                            </div>
                            <div>
                                <div className="flex items-center space-x-2">
                                    <FaEnvelope className="text-[#a07c5c] w-5 h-5"/>
                                    <label htmlFor="email" className="text-sm font-medium text-gray-700">Adres
                                        e-mail</label>
                                </div>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#795b40] focus:ring focus:ring-[#795b40] focus:ring-opacity-50"
                                />
                            </div>
                            <div>
                                <div className="flex items-center space-x-2">
                                    <FaCommentAlt className="text-[#a07c5c] w-5 h-5"/>
                                    <label htmlFor="message"
                                           className="text-sm font-medium text-gray-700">Wiadomość</label>
                                </div>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows="4"
                                    className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#795b40] focus:ring focus:ring-[#795b40] focus:ring-opacity-50"
                                />
                            </div>
                            <button
                                type="submit"
                                className="bg-[#a07c5c] hover:bg-[#795b40] text-white font-bold py-2 px-4 rounded flex items-center"
                            >
                                <FaSearch className="mr-2"/> Wyślij
                            </button>
                            {formError && <p className="text-red-500 mt-4">{formError}</p>}
                            {formSuccess && <p className="text-green-500 mt-4">{formSuccess}</p>}
                            {isSubmitting && <p>Wysyłanie...</p>}
                        </form>
                    </div>

                </div>
            </div>
            {/* New Map Section - place this before Similar Listings */}
            {mapCoordinates && (
                <div className="mt-10">
                    <h2 className="text-3xl font-bold mb-6">Lokalizacja</h2>
                    <hr className="border-gray-400 py-2"/>
                    <span>{formatText(listing.miejscowosc_dzielnica)} </span>
                    <span>ul. {formatText(listing.ulica)}</span>
                    <div className="w-full h-96">
                        <MapContainer
                            center={mapCoordinates}
                            zoom={13}
                            scrollWheelZoom={false}
                            style={{height: '100%', width: '100%'}}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={mapCoordinates}>
                                <Popup>
                                    {listing.ulica}, {listing.miejscowosc_dzielnica}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            )}

            <div className="mt-10">
            <h2 className="text-3xl font-bold mb-6">Podobne ogłoszenia</h2>
                <hr className="border-gray-400 py-2"/>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {similarListings.map((item) => (
                        <Card key={item.id_ogloszenia} item={item}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ListingDetails;
